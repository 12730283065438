import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
const Skills = () => {
  let skills = [
    "JavaScript",
    "ES6",
    "jQuery",
    "HTML",
    "CSS",
    "SASS",
    "SCSS",
    "Gulp JS",
    "Grunt JS",
    "JSON",
    "REST APIs",
    "Node JS",
    "Express JS",
    "Webpack",
    "React",
    "Redux",
    "oAuth",
    "Parcel JS",
    "Shopify\nLiquid",
    "Lemonstand\nTwig",
    "Hubspot\nHubble",
    "npm",
    "Git",
    "PostgreSQL",
  ]
  return (
    <Layout>
      <SEO title="Danton Nguyen: Skills and Experience" />
      <article className="article">
        <div>
          <h1 className="title">skills & experience</h1>

          <p>My skills revolve around front end development.</p>
          <p>
            I've had most of my experience with modern web development
            technologies and E-commerce CMS.
          </p>
        </div>

        <ul className="skillsPage__skillTable">
          {skills.map(skill => (
            <li key={skill} className="skillItem">
              {skill}
            </li>
          ))}
        </ul>
      </article>
    </Layout>
  )
}

export default Skills
